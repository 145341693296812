<template>
  <div class="page-wrapper">
    <img
      v-if="shouldShowMainBanner"
      :src="mainBannerImg"
      class="banner-img"
      alt="main banner"
      @click="handleMainBannerClick"
      :style="{ cursor: isClickable ? 'pointer' : 'default' }"
    />
    <img v-if="shouldShowSubBanner" :src="subBannerImg" class="banner-img" alt="sub banner" />
    <VueWrapperNarrow>
      <div class="form-wrapper">
        <VueHeadline level="2" class="title">Ürün Geri Bildirimi</VueHeadline>

        <VueText weightLevel="3">Konu</VueText>
        <ValidationProvider
          ref="validatorProvider"
          name="Konu"
          rules="required|min:3|max:100"
          v-slot="{ errors }"
        >
          <span class="error-message">{{ errors[0] }}</span>
          <input v-model="subject" class="input" type="text" placeholder="Konu" />
        </ValidationProvider>
        <VueText weightLevel="3">Ürün</VueText>
        <ValidationProvider name="Ürün" rules="required" v-slot="{ errors }">
          <span class="error-message">{{ errors[0] }}</span>
          <multiselect
            v-model="selectedSkues"
            placeholder="Geri bildirim vermek istediğiniz ürünü seçiniz"
            label="pmdsc"
            :show-labels="false"
            track-by="pmdsc"
            :multiple="true"
            :options="skuList"
            :searchable="false"
            :close-on-select="false"
          >
            <template slot="selection" slot-scope="{ values }">
              <span class="multiselect__single" v-if="values.length">
                {{ values.length }} ürün seçildi
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.pmdsc }}</span>
                <span class="option__icon"></span>
              </div>
            </template>
          </multiselect>
        </ValidationProvider>
        <VueText weightLevel="3">Ürün Adedi (Paket Adedi)</VueText>
        <VueInput
          name="skuCount"
          id="skuCount"
          type="number"
          placeholder="Ürün Adedi"
          v-model="skuCount"
          validationRules="required"
          validatorName="Ürün Adedi"
          :class="[{ selected: skuCount }, 'input-item sku-count']"
        ></VueInput>
        <VueText weightLevel="3">Mesajınız</VueText>
        <ValidationProvider name="Mesaj" rules="required|min:3|max:5000" v-slot="{ errors }">
          <span class="error-message">{{ errors[0] }}</span>
          <textarea v-model="message" class="textarea" placeholder="Mesaj"></textarea>
        </ValidationProvider>
        <VueText weightLevel="3">Fotoğraflar</VueText>
        <span v-if="!firstImgUrl && !lastImgUrl" class="error-message"
          >Sizlere daha hızlı dönüş yapmamıza imkan verebileceğinden, fotoğraf yüklemenizi önemle
          rica ederiz</span
        >
        <div class="image-inputs">
          <div class="image-input" @click.self="chooseFirstImg">
            <img
              :src="firstImgSrc"
              :class="firstImgUrl ? 'preview-img' : 'photo-camera'"
              alt="photo-camera"
            />
            <span v-if="!firstImgUrl">Fotoğraf Ekle</span>
            <button v-if="firstImgUrl" class="delete-btn" @click="deleteFirstImg()" />
            <input
              @change="getFirstPhoto"
              ref="fileInput1"
              type="file"
              accept="image/*"
              hidden="true"
            />
          </div>
          <div class="image-input" @click.self="chooseLastImg">
            <img
              :src="lastImgSrc"
              :class="lastImgUrl ? 'preview-img' : 'photo-camera'"
              alt="photo-camera"
            />
            <span v-if="!lastImgUrl">Fotoğraf Ekle</span>
            <button v-if="lastImgUrl" class="delete-btn" @click="deleteLastImg()" />
            <input
              @change="getLastPhoto"
              ref="fileInput2"
              type="file"
              accept="image/*"
              hidden="true"
            />
          </div>
        </div>
        <div class="sample-photos">
          <VueText class="title" weightLevel="2">Örnek Fotoğraflar</VueText>
          <div class="row">
            <div class="box">
              <img class="box-img" src="@/assets/images/sample-photo-1.png" alt="sample photo" />
              <span class="box-text">Paket Kodu</span>
            </div>
            <div class="box">
              <img class="box-img" src="@/assets/images/sample-photo-2.png" alt="sample photo" />
              <span class="box-text">Ürün Hatası</span>
            </div>
          </div>
        </div>
        <VueText weightLevel="3">Adres</VueText>
        <ValidationProvider name="Adres" rules="required|min:3|max:5000" v-slot="{ errors }">
          <span class="error-message">{{ errors[0] }}</span>
          <textarea
            v-model="address"
            class="textarea address"
            placeholder="Telafi ya da ürün alımı gerektiren süreçlerde size ulaşabileceğimiz teslimat adresi"
          ></textarea>
        </ValidationProvider>
        <BrandButton @click="send()" :disabled="disable" class="btn-send" type="submit"
          >GÖNDER</BrandButton
        >
      </div>
    </VueWrapperNarrow>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import Common from '@/services/Api/common';
import Product from '@/services/Api/product';
import { ValidationProvider } from 'vee-validate';
import loadImage from 'blueimp-load-image';
import Multiselect from 'vue-multiselect';

export default {
  name: 'Contact',
  data() {
    return {
      subject: '',
      message: '',
      firstImgUrl: null,
      lastImgUrl: null,
      firstImage: '',
      lastImage: '',
      skuList: [],
      skuCount: null,
      address: '',
      selectedSkues: [],
      mainBannerImg: null,
      subBannerImg: null,
      bannerLink: null,
      isLoadingBanner: false,
      isBannerVisible: false,
    };
  },
  components: {
    VueText,
    BrandButton,
    VueWrapperNarrow,
    VueHeadline,
    ValidationProvider,
    VueInput,
    Multiselect,
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
    disable() {
      return (
        this.subject.length < 3 ||
        this.message.length < 3 ||
        this.selectedSkues.length === 0 ||
        this.address.length < 3 ||
        this.skuCount < 1
      );
    },
    firstImgSrc() {
      return this.firstImgUrl || require('../../../assets/images/photo-camera.png');
    },
    lastImgSrc() {
      return this.lastImgUrl || require('../../../assets/images/photo-camera.png');
    },
    shouldShowMainBanner() {
      return !this.isLoadingBanner && this.mainBannerImg && this.isBannerVisible;
    },
    shouldShowSubBanner() {
      return !this.isLoadingBanner && this.subBannerImg;
    },
    isClickable() {
      return Boolean(this.bannerLink?.trim());
    },
    formattedBannerUrl() {
      if (!this.bannerLink?.trim()) return null;
      return this.bannerLink.startsWith('http') ? this.bannerLink : `https://${this.bannerLink}`;
    },
  },
  created() {
    this.setBottomBarStatus(false);
    this.getSkuList();
    this.getBannerImage();
  },
  mounted() {
    document.querySelector('.sku-count').addEventListener('keypress', function(evt) {
      if ((evt.which != 8 && evt.which < 48) || evt.which > 57) {
        evt.preventDefault();
      }
    });
  },
  methods: {
    async getBannerImage() {
      try {
        this.isLoadingBanner = true;
        const response = await Common.getFeedbackPlatformBannerInfo();

        if (!response?.data?.Data) {
          console.error('Invalid banner data format');
          return;
        }

        const {
          mainBannerImg,
          subBannerImageWeb: subBannerImg,
          link,
          isVisible,
        } = response.data.Data;

        this.mainBannerImg = mainBannerImg;
        this.subBannerImg = subBannerImg;
        this.bannerLink = link;
        this.isBannerVisible = isVisible;
      } catch (error) {
        console.error('Error fetching banner information:', error);
      } finally {
        this.isLoadingBanner = false;
      }
    },
    handleMainBannerClick() {
      if (!this.formattedBannerUrl) {
        console.debug('Banner click ignored: No valid URL available');
        return;
      }

      try {
        window.open(this.formattedBannerUrl, '_blank', 'noopener,noreferrer');
      } catch (error) {
        console.error('Error opening banner link:', error);
      }
    },
    getSkuList() {
      Product.getSkuList().then(res => {
        if (res.data && res.data.Data) {
          this.skuList = res.data.Data?.skus;
        }
      });
    },
    limitText(count) {
      return `${count} tane daha seçili`;
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    send() {
      let imageList = [];
      let image1 = this.lastImage.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
      let image2 = this.firstImage.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
      imageList.push(image1);
      imageList.push(image2);

      let model = {
        subject: this.subject,
        text: this.message,
        images: imageList.filter(Boolean),
        skuNames: this.selectedSkues.map(i => i.pmdsc),
        quantity: Number(this.skuCount),
        address: this.address,
      };
      Common.sendMail(model)
        .then(res => {
          if (res.data && res.data.Data) {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="font-size: 32px; color: #000; font-weight: 500;">Mesajını aldık</div>
                          <div style="font-size: 32px; margin-top: 12px;">En yakın zamanda seninle iletişime geçeceğiz.</div>`,
              firstButtonText: 'TAMAM',
              firstButtonFn: () => {
                this.$store.dispatch('app/setModalConfig', { isModalOpened: false });
                this.$refs.validatorProvider.reset();
                this.$router.push({ name: 'feed' });
              },
              modalConfirmationText: '',
              modalType: 'alert',
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          } else if (res.data && res.data.Error) {
            const modalConfig = {
              isModalOpened: true,
              modalText: `${res.data.Error.Message}`,
              firstButtonText: 'TAMAM',
              firstButtonFn: () => {
                this.$store.dispatch('app/setErrorModalConfig', { isModalOpened: false });
                this.$refs.validatorProvider.reset();
              },
              noTitle: true,
              alertType: 'warn',
            };
            this.$store.dispatch('app/setErrorModalConfig', modalConfig);
          }
        })
        .catch(err => console.log(err));
    },
    chooseFirstImg() {
      this.$refs.fileInput1.click();
    },
    chooseLastImg() {
      this.$refs.fileInput2.click();
    },
    getFirstPhoto({ target }) {
      this.readFile(target.files)
        .then(
          data => {
            target.value = '';
            this.firstImgUrl = data.url;
          },
          loadImage(target.files[0], { canvas: true, maxWidth: 1280 }).then(data => {
            let base64Image = data.image.toDataURL('image/jpeg', 0.9);
            this.firstImage = base64Image;
          }),
        )
        .catch(err => {
          target.value = '';
          console.log(err);
        });
    },
    getLastPhoto({ target }) {
      this.readFile(target.files)
        .then(
          data => {
            target.value = '';
            this.lastImgUrl = data.url;
          },
          loadImage(target.files[0], { canvas: true, maxWidth: 1280 }).then(data => {
            let base64Image = data.image.toDataURL('image/jpeg', 0.9);
            this.lastImage = base64Image;
          }),
        )
        .catch(err => {
          target.value = '';
          console.log(err);
        });
    },
    deleteFirstImg() {
      const input = this.$refs.fileInput1;
      input.type = 'text';
      input.type = 'file';
      this.firstImgUrl = null;
      this.firstImage = '';
    },
    deleteLastImg() {
      const input = this.$refs.fileInput2;
      input.type = 'text';
      input.type = 'file';
      this.lastImgUrl = null;
      this.lastImage = '';
    },
    readFile(files) {
      return new Promise((resolve, reject) => {
        if (!files || files.length === 0) {
          resolve();
          return;
        }
        const file = files[0];
        if (/^image\/\w+$/.test(file.type)) {
          if (URL) {
            resolve({
              loaded: true,
              name: file.name,
              type: file.type,
              url: URL.createObjectURL(file),
            });
          } else {
            reject(new Error('Browser desteklenmiyor.'));
          }
        } else {
          reject(new Error('Lütfen image tipinde bir görsel seçiniz.'));
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.page-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 20px;
}
.form-wrapper {
  padding-top: 38px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.input {
  color: #23303d;
  width: 100%;
  height: 60px;
  margin: 10px 0 20px;
  padding: 20px;
  border-radius: 4px;
  border: solid 0.5px #23303d;
  font-size: 22px;
  line-height: 1.36;
  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: normal;
    color: #23303d;
  }
}
.textarea {
  color: #23303d;
  width: 100%;
  min-height: 200px;
  padding: 20px 17px 20px 20px;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 0.5px #23303d;
  font-size: 22px;
  line-height: 1.36;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
  &.address {
    margin-bottom: 0;
  }

  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: normal;
    color: #23303d;
  }
}
.btn-send {
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  height: 60px !important;
  margin-top: 30px;
}
.error-message {
  font-size: 12px;
  color: #e23b33;
  position: relative;
  bottom: -7px;
}
.image-inputs {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.image-input {
  height: 160px;
  width: 322px;
  min-width: 1px;
  min-height: 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: initial;
  background-image: url('~@/assets/images/choose-file.png');
  cursor: pointer;
  .photo-camera {
    pointer-events: none;
  }
  .preview-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  span {
    margin-top: 15px;
    pointer-events: none;
  }
  input[type='file'] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
.delete-btn {
  background-image: url('~@/assets/images/delete.png');
  background-color: transparent;
  width: 24px;
  height: 24px;
  border: none;
  box-shadow: none;
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 10px;
}
.sample-photos {
  margin-top: 20px;
  margin-bottom: 20px;
  .title {
    font-size: 16px;
    color: #23303d;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .row {
    display: flex;
  }
  .box {
    &-img {
      display: block;
      margin-bottom: 7px;
    }
    &-text {
      font-size: 14px;
      color: #23303d;
      opacity: 0.5;
    }
    &:first-child {
      margin-right: 20px;
    }
  }
}
.sku-wrapper {
  margin: 10px 0 20px;
  /deep/ input {
    height: 60px;
    cursor: pointer;
    padding-top: 12px;
  }
  /deep/ label {
    top: 50% !important;
  }
  &.selected {
    /deep/ label {
      display: none;
    }
  }
  .picker-toggle {
    position: absolute;
    right: palette-space-level(20);
    top: palette-space-level(30);
    cursor: pointer;
  }
}
.sku-modal {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  border: solid 1px #79838e;
  border-radius: 4px;
  margin-top: 20px;
  min-width: 663px;
  max-height: 400px;
  overflow-y: auto;
  .sku-item {
    /deep/ span {
      margin-left: 40px;
    }
  }
}
.sku-count {
  margin: 10px 0 20px;
  /deep/ input {
    height: 60px;
    padding-top: 12px;
    appearance: textfield;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  /deep/ label {
    top: 50% !important;
  }
  &.selected {
    /deep/ label {
      display: none;
    }
  }
}
/deep/ .multiselect {
  margin-top: 10px;
  margin-bottom: 20px;
  .multiselect__content-wrapper {
    max-height: 400px !important;
    border-radius: 4px;
    border: 1px solid #79838e;
  }
  .multiselect__tags {
    min-height: 60px;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-left: 20px;
    border: 1px solid #79838e;
    border-radius: 4px;
  }
  .multiselect__tag {
    background: none;
    margin-bottom: 0;
    font-size: 18px;
  }
  .multiselect__placeholder {
    font-size: 16px;
    color: #23303d;
    font-weight: 300;
    margin-bottom: 0;
  }
  .multiselect__option {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 21px;
    font-weight: 300;
    border-bottom: 0.5px solid rgba(180, 194, 211, 0.6);
    padding-left: 40px;
    padding-right: 20px;
  }
  .multiselect__option--highlight {
    background: #f8f9f9;
    color: #23303d;
  }
  .multiselect__option--selected {
    background: #f8f9f9;
    font-weight: 300;
    &.multiselect__option--highlight {
      color: #23303d;
      font-weight: 300;
    }
    .option__desc {
      .option__icon {
        background-color: #6dd400;
        border-color: #6dd400;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 4px;
          right: 7px;
          width: 5px;
          height: 8px;
          background-color: transparent;
          border-right: solid 2px #fff;
          border-bottom: solid 2px #fff;
          transform: rotate(45deg);
        }
      }
    }
  }
  .option__desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .option__icon {
      width: 21px;
      height: 21px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #23303d;
    }
  }
  &--active {
    .multiselect__select {
      transform-origin: center;
    }
  }
  .multiselect__select {
    height: 60px;
    &:before,
    &:after {
      content: '';
      background: #000;
      -webkit-border-radius: 0.2em;
      border-radius: 0.2em;
      display: block;
      height: 2px;
      position: absolute;
      right: 0;
      width: 7px;
      color: #000;
      border: none;
    }
    &:before {
      top: 48%;
      right: 19px;
      transform: rotate(45deg);
    }
    &:after {
      top: 54%;
      right: 15px;
      transform: rotate(-45deg);
    }
  }
  .multiselect__single {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 22px;
  }
}
</style>
